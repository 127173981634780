import React, { Suspense, useState } from "react";
import "./LandingContact.css";
import "../../pages/Home/Home.css";
import "../../pages/LandingPages/DryCarpet/DryCarprt.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
const MyLazyComponent = React.lazy(() => import("./MyLazyComponent"));

const FabricLanding = ({ cleaning, CR, wet }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [mailerState, setMailerState] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const submitEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("You're good!");

    try {
      await axios(
        "https://nodemailer.node.purenbrightcleaning.com.au/sendEmail",
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          data: mailerState,
        }
      )
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            console.log(res.data);
            console.log("Message Sent");
            navigate("/thank-you");
            setLoading(false);
          } else if (res.status === 402) {
            console.log("Message failed to send");
          }
        })
        .catch((err) => {
          console.log(err);
        });
      await axios("https://rug-cleaning-service.onrender.com/sendEmail", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        data: mailerState,
      })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            console.log("Message Sent");
          } else if (res.status === 402) {
            console.log("Message failed to send");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error(error);
    }
  };

  function handleStateChange(e) {
    setMailerState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  return (
    <>
      <div className={wet ? "contactL section wet" : "contactL section"}>
        <div className="contact content">
          {!wet ? (
            <div className="contact img">
              <div className="types info">
                <h2>
                  {CR === "pressure"
                    ? "Get Help For All Your Pressure Cleaning Needs"
                    : "Get Help For All Your Couch Cleaning Needs"}
                </h2>
                <div id="highlight"></div>
                <img
                  src={
                    CR === "/fabric"
                      ? require("../../../Assets/Images/Banner/Fabric.jpeg")
                      : CR === "/leather"
                      ? require("../../../Assets/Images/Banner/Leather.jpeg")
                      : require("../../../Assets/Images/Banner/Pressure-1.jpeg")
                  }
                  alt=""
                  loading="lazy"
                />
                <p>
                  {CR !== "pressure"
                    ? "We understand how much you love your Couch so as we, our fully trained technicians bring your Couch back to life."
                    : "Our pressure cleaning service delivers top-quality results, leaving surfaces spotless and refreshed with powerful, thorough cleaning."}
                </p>
              </div>
            </div>
          ) : null}
          {!wet ? (
            <div className="tubeQ">
              {CR === "pressure" ? (
                <div className="pressure-vid">
                  <h2>Our Pressure Cleaning Process</h2>
                  <iframe
                    title="Youtube"
                    srcdoc={`
                    <style>
                    body, .full {
                        width: 100%;
                        height: 100%;
                        margin: 0;
                        position: absolute;
                        display: flex;
                        justify-content: center;
                        object-fit: cover;
                    }
                </style>
                <a
                    href='https://www.youtube.com/embed/IBkj8G8dOOM?si=pKgAcy4pA32C4ha4&amp;controls=0'
                    class='full'
                >
                    <img
                        src=${require("../../../Assets/Images/Banner/pressure-thumbnail.jpg")}
                        class='full'
                    />
                    <svg
                        version='1.1'
                        viewBox='0 0 68 48'
                        width='68px'
                        style='position: relative;'
                    >
                        <path d='M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z' fill='#f00'></path>
                        <path d='M 45,24 27,14 27,34' fill='#fff'></path>
                    </svg>
                </a>
                    `}
                    frameborder="0"
                  ></iframe>
                </div>
              ) : (
                <>
                  <h2>We Clean all types of Couches</h2>
                  {/* {cleaning === "carpet" ? ( */}
                  <img
                    src={require("../../../Assets/Images/Banner/All-types-couch.jpeg")}
                    alt=""
                  />
                </>
              )}
            </div>
          ) : null}
          <div className={wet ? "contact info wet" : "contact info"}>
            <section
              className="contact1"
              id="contact"
              style={{ width: "100%" }}
            >
              <h2>Free Quote</h2>
              <form onSubmit={submitEmail} action="">
                <div className="name">
                  <div className="block">
                    <input
                      disabled={loading}
                      type="text"
                      placeholder="Name*"
                      name="name"
                      className="box"
                      value={mailerState.name}
                      onChange={handleStateChange}
                    />
                  </div>
                </div>

                <div className="name">
                  <div className="block">
                    <input
                      disabled={loading}
                      type="number"
                      placeholder="Phone Number*"
                      name="phone"
                      className="box"
                      value={mailerState.phone}
                      onChange={handleStateChange}
                    />
                  </div>
                </div>

                <div className="name">
                  <div className="block">
                    <input
                      disabled={loading}
                      type="email"
                      placeholder="E-mail*"
                      name="email"
                      className="box"
                      value={mailerState.email}
                      onChange={handleStateChange}
                    />
                  </div>
                </div>

                <textarea
                  disabled={loading}
                  name="message"
                  placeholder="Message..."
                  className="box"
                  id=""
                  cols="30"
                  rows="10"
                  value={mailerState.message}
                  onChange={handleStateChange}
                />

                <div
                  style={{ transformOrigin: "0 0", width: "100%" }}
                  className="captcha"
                >
                  <Suspense fallback={<p>Captcha is loading...</p>}>
                    <MyLazyComponent />
                  </Suspense>
                </div>

                {loading ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <button type="submit" className="submit" id="submit">
                    <span className="submit">Submit</span>
                    <img
                      className="submit"
                      src={require("../../../Assets/Images/Icons/enquire-blue.png")}
                      alt=""
                    />
                  </button>
                )}
              </form>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default FabricLanding;
